<template>
  <section class="pending-actions">
    <h2 class="mb-2" style="font-weight: 600">Pending Actions</h2>

    <div
      class="licence-details px-2"
      style=""
      v-if="currentMove.pendingActions['dvlaLicence']['pending']"
    >
      <div class="d-flex align-items-center">
        <h3 class="mb-0" style="font-weight: 600">Confirm your licence details</h3>

        <i
          class="icon mdi mdi-chevron-down"
          v-if="!expandLicenceDetails"
          @click="expandLicenceDetails = !expandLicenceDetails"
        ></i>
        <i
          class="icon mdi mdi-chevron-up animate__rotateIn animate__animated animate__faster"
          v-if="expandLicenceDetails"
          @click="expandLicenceDetails = !expandLicenceDetails"
        ></i>
      </div>

      <div v-if="expandLicenceDetails">
        <p>
          We were unable to process your driving licence update with the current details. Please
          take a moment to check the information below and make any necessary corrections.
        </p>
        <div class="d-flex justify-content-center mb-1">
          <table>
            <tbody>
              <tr>
                <td style="font-weight: 600">Title</td>
                <td>
                  <div class="input-search-container mb-1">
                    <vSelect
                      class="w-100 fs-md"
                      :clearable="false"
                      :options="titles"
                      :placeholder="`Mr`"
                      :multiple="false"
                      v-model="dvlaQuestions['titleLicense']"
                    >
                    </vSelect>
                    <p class="text-danger error mb-0" v-if="errors.titleLicense">
                      * This field is required
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="font-weight: 600">First Name</td>
                <td>
                  <input type="text" v-model="dvlaQuestions['firstNameLicense']" />

                  <p class="text-danger error mb-0" v-if="errors.firstNameLicense">
                    * This field is required
                  </p>
                </td>
              </tr>

              <tr>
                <td style="font-weight: 600">Middle Name</td>
                <td>
                  <input type="text" v-model="dvlaQuestions['middleNameLicense']" />
                  <p class="text-danger error mb-0" v-if="errors.middleNameLicense">
                    * This field is required
                  </p>
                </td>
              </tr>

              <tr>
                <td style="font-weight: 600">Last Name</td>
                <td>
                  <input type="text" v-model="dvlaQuestions['surnameLicense']" />
                  <p class="text-danger error mb-0" v-if="errors.surnameLicense">
                    * This field is required
                  </p>
                </td>
              </tr>

              <tr>
                <td style="font-weight: 600">DoB</td>
                <td>
                  <div style="margin-bottom: 5px">
                    <DateMultiSelect
                      :dateModel="dob"
                      @formattedDate="(value) => (this.dob = value)"
                      :isDOB="true"
                      :showMessage="false"
                    />
                    <p class="text-danger error mb-0" v-if="errors.dob">* This field is required</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="pr-1" style="font-weight: 600">Postcode on licence</td>
                <td>
                  <input type="text" v-model="dvlaQuestions['postCodePrinted']" />
                  <p class="text-danger error mb-0" v-if="errors.postCodePrinted">
                    * This field is required
                  </p>
                </td>
              </tr>

              <tr>
                <td class="pr-1" style="font-weight: 600">Address on licence</td>
                <td>
                  <input type="text" v-model="dvlaQuestions['addressPrintedLicense']" />
                  <p class="text-danger error mb-0" v-if="errors.addressPrintedLicense">
                    * This field is required
                  </p>
                </td>
              </tr>

              <tr>
                <td class="pr-1" style="font-weight: 600">NI Number</td>
                <td>
                  <input type="text" v-model="NInumber" />
                  <p class="text-danger error mb-0" v-if="errors.NInumber">
                    * Please enter a correct number
                  </p>
                </td>
              </tr>

              <tr>
                <td class="pr-1" style="font-weight: 600">Issue Number</td>
                <td>
                  <input type="text" v-model="dvlaQuestions['driversLicenseIssueNumber']" />
                  <p class="text-danger error mb-0" v-if="errors.driversLicenseIssueNumber">
                    * Issue number must be 2 digits.
                  </p>
                </td>
              </tr>

              <tr>
                <td class="pr-1" style="font-weight: 600">Photocard Number</td>
                <td>
                  <input type="text" v-model="dvlaQuestions['photocardNumber']" />
                  <p class="text-danger error mb-0" v-if="errors.photocardNumber">
                    * Enter a valid photocard number
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="d-flex justify-content-center mb-2"
          v-if="
            currentMove.pendingActions['dvlaLicence']['pending'] &&
            !currentMove.pendingActions['dvlaVehicle']['pending']
          "
        >
          <p class="mb-0 already-correct" @click="confirmDetailsService('DVLA-License')">
            Information is already correct
          </p>

          <!-- <button class="ml-2 button button-update" @click="updateDetailsService()">Update</button> -->
          <button class="ml-2 button button-update" @click="sendUpdate()">Update</button>
        </div>

        <div class="row">
          <div class="col-6 pr-0 text-center">
            <img :src="`${urlBucket}/dashboard/issue-number-on-license.png`" width="170px" />
            <p class="mb-0" style="font-size: 12px">Issue Number</p>
          </div>
          <div class="col-6 pl-0 text-center">
            <img :src="`${this.urlBucket}/form/images/photoCard.png`" width="200px" />
            <p class="mb-0" style="font-size: 12px">Photocard Number</p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="v5c-details mt-2 px-2"
      style=""
      v-if="currentMove.pendingActions['dvlaVehicle']['pending']"
    >
      <div class="d-flex align-items-center">
        <h3 class="mb-0" style="font-weight: 600">Confirm your V5C details</h3>

        <i
          class="icon mdi mdi-chevron-down"
          v-if="!expandV5cDetails"
          @click="expandV5cDetails = !expandV5cDetails"
        ></i>
        <i
          class="icon mdi mdi-chevron-up animate__rotateIn animate__animated animate__faster"
          v-if="expandV5cDetails"
          @click="expandV5cDetails = !expandV5cDetails"
        ></i>
      </div>

      <div v-if="expandV5cDetails">
        <p>With this information we were unable to access your driver record.</p>
        <div class="d-flex justify-content-center">
          <table>
            <tbody>
              <tr>
                <td style="font-weight: 600"><span class="mr-1">Postcode on V5C</span></td>
                <td>
                  <input type="text" v-model="postcodeOnV5C" />
                  <p class="text-danger error mb-0" v-if="errorsV5CDetails['postcodeOnV5C']">
                    * Enter a valid postcode
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div
          v-for="(item, index) in dvlaQuestions['vehiclesRegistered']"
          :key="index"
          class="d-flex justify-content-center mb-1"
        >
          <table>
            <tbody>
              <tr>
                <td style="font-weight: 600">
                  <span class="mr-1">Vehicle Registration Number</span>
                </td>
                <td>
                  <input
                    type="text"
                    v-model="dvlaQuestions['vehiclesRegistered'][index]['carRegistration']"
                  />
                  <p
                    class="text-danger error mb-0"
                    v-if="
                      touched && dvlaQuestions['vehiclesRegistered'][index]['carRegistration'] == ''
                    "
                  >
                    * This field is required
                  </p>
                </td>
              </tr>
              <tr>
                <td style="font-weight: 600">V5C Reference Number</td>
                <td>
                  <input
                    type="text"
                    v-model="dvlaQuestions['vehiclesRegistered'][index]['v5cNumber']"
                  />

                  <p
                    class="text-danger error mb-0"
                    v-if="touched && dvlaQuestions['vehiclesRegistered'][index]['v5cNumber'] == ''"
                  >
                    * This field is required
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="d-flex justify-content-center mb-2"
          v-if="
            !currentMove.pendingActions['dvlaLicence']['pending'] &&
            currentMove.pendingActions['dvlaVehicle']['pending']
          "
        >
          <p class="mb-0 already-correct" @click="confirmDetailsService('DVLA-v5c')">
            Information is already correct
          </p>

          <!-- <button class="ml-2 button button-update" @click="updateV5cDetails()">Update</button> -->
          <button class="ml-2 button button-update" @click="sendUpdate()">Update</button>
        </div>

        <div
          class="comfirm-both-dvla-details d-flex justify-content-center mb-2"
          v-if="
            currentMove.pendingActions['dvlaLicence']['pending'] &&
            currentMove.pendingActions['dvlaVehicle']['pending']
          "
        >
          <p class="mb-0 already-correct" @click="confirmDetailsService()">
            Information is already correct
          </p>

          <button class="ml-2 button button-update" @click="sendUpdate()">Update</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { titles } from "../lists.js";
import DateMultiSelect from "../ecommerce/DateMultiSelect.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import axiosCustom from "@axios";
import { isValid } from "postcode";
import axios from "axios";

export default {
  name: "pending-actions",
  components: { vSelect, DateMultiSelect },
  data() {
    return {
      expandLicenceDetails: false,
      expandV5cDetails: false,
      titles,
      dvlaQuestions: {},
      //Licence Details
      NInumber: "",
      dob: "",
      sending: false,
      errors: {
        //Licence Details
        titleLicense: false,
        firstNameLicense: false,
        middleNameLicense: false,
        surnameLicense: false,
        dob: false,
        postCodePrinted: false,
        addressPrintedLicense: false,
        NInumber: false,
        driversLicenseIssueNumber: false,
        photocardNumber: false,
      },
      //V5C Details
      touched: false,
      postcodeOnV5C: "",
      errorsV5CDetails: {
        postcodeOnV5C: false,
      },
    };
  },
  mounted() {
    let move = Object.assign({}, this.currentMove);
    this.dvlaQuestions = move.dvlaQuestions;
    this.dob = move.userDetails["dateOfBirth"];
    this.NInumber = move.services["selectedNINO"];

    //V5C Details
    this.postcodeOnV5C =
      move.dvlaQuestions["postCodePrintedV5C"] === "No"
        ? move.dvlaQuestions["postCodeVehicleRegistered"]
        : move.moveDetails["moveFromPostCode"];
  },
  methods: {
    validateInputs() {
      if (!this.dvlaQuestions["titleLicense"] || this.dvlaQuestions["titleLicense"].length == 0) {
        this.errors["titleLicense"] = true;
      } else {
        this.errors["titleLicense"] = false;
      }

      if (!this.dvlaQuestions["firstNameLicense"]) {
        this.errors["firstNameLicense"] = true;
      } else {
        this.errors["firstNameLicense"] = false;
      }

      // if (!this.dvlaQuestions["middleNameLicense"]) {
      //   this.errors["middleNameLicense"] = true;
      // } else {
      //   this.errors["middleNameLicense"] = false;
      // }

      if (!this.dvlaQuestions["surnameLicense"]) {
        this.errors["surnameLicense"] = true;
      } else {
        this.errors["surnameLicense"] = false;
      }

      if (!this.dob) {
        this.errors["dob"] = true;
      } else {
        this.errors["dob"] = false;
      }

      if (!this.dvlaQuestions["postCodePrinted"]) {
        this.errors["postCodePrinted"] = true;
      } else {
        this.errors["postCodePrinted"] = false;
      }

      if (!this.dvlaQuestions["addressPrintedLicense"]) {
        this.errors["addressPrintedLicense"] = true;
      } else {
        this.errors["addressPrintedLicense"] = false;
      }

      if (!/^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]{1}?\s*$/.test(this.NInumber)) {
        this.errors["NInumber"] = true;
      } else {
        this.errors["NInumber"] = false;
      }

      if (!/^\d{2}$/.test(this.dvlaQuestions["driversLicenseIssueNumber"])) {
        this.errors["driversLicenseIssueNumber"] = true;
      } else {
        this.errors["driversLicenseIssueNumber"] = false;
      }

      if (!/^[A-Za-z]{2}[A-Za-z0-9][0-9]{7,9}$/.test(this.dvlaQuestions["photocardNumber"])) {
        this.errors["photocardNumber"] = true;
      } else {
        this.errors["photocardNumber"] = false;
      }
    },
    confirmDetailsService(option) {
      if (this.sending ) return;
      this.sending = true;
      

      const sendData = (option) => {
        axiosCustom
          .post("api/user/confirmDetailsService", {
            email: this.user.email,
            option,
          })
          .then(async (res) => {
            console.log("confirmDetailsService", res);
            await this.getDetails();
            this.redirectToHome();
            this.sucessDialog("Your details have been confirmed!");
            this.sending = false;

            setTimeout(() => {}, 200);
          })
          .finally(() => {});
      };

      if (!option) {
        // const confirmLicence = axiosCustom.post("api/user/confirmDetailsService", {
        //   email: this.user.email,
        //   option: 'DVLA-License',
        // });

        // const confirmV5C = axiosCustom.post("api/user/confirmDetailsService", {
        //   email: this.user.email,
        //   option: 'DVLA-v5c',
        // });

        // Promise.all([confirmLicence, confirmV5C])
        axiosCustom
          .post("api/user/confirmDetailsService", {
            email: this.user.email,
            option: "DVLA-License",
          })
          .then(async (res) => {
            console.log("confirmDetailsService", res);
            await this.getDetails();
            this.redirectToHome();
            this.sucessDialog("Your details have been confirmed!");
            this.sending = false;

            setTimeout(() => {}, 200);
          })
          .finally(() => {});
      } else {
        sendData(option);
      }
    },
    // updateDetailsService() {
    //   this.validateInputs();
    //   if (this.sending || Object.values(this.errors).includes(true)) return;
    //   this.sending = true;

    //   let move = Object.assign({}, this.currentMove);

    //   move.dvlaQuestions = this.dvlaQuestions;
    //   move.services.selectedNINO = this.NInumber;
    //   move.userDetails["dateOfBirth"] = this.dob.toISOString().split("T")[0];

    //   axiosCustom
    //     .post("api/user/updateDetailsService", { move })
    //     .then(async (res) => {
    //       console.log("updateDetailsService", res);
    //       await this.getDetails();
    //       this.redirectToHome();
    //       this.sucessDialog();
    //       this.sending = false;

    //       setTimeout(() => {}, 200);
    //     })
    //     .finally(() => {});
    // },
    async validateV5CDetails() {
      return new Promise((resolve, reject) => {
        const hasInvalidVehicle = this.dvlaQuestions.vehiclesRegistered.some((v) => {
          return v["v5cNumber"] == "" || v["carRegistration"] == "";
        });

        if (hasInvalidVehicle) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    },
    validateV5CPostcode() {
      if (isValid(this.postcodeOnV5C) == false) {
        this.errorsV5CDetails["postcodeOnV5C"] = true;
        return true;
      } else {
        this.errorsV5CDetails["postcodeOnV5C"] = false;
        return false;
      }
    },
    // async updateV5cDetails() {
    //   this.touched = true;
    //   if ((await this.validateV5CDetails()) || this.validateV5CPostcode() || this.sending) return;
    //   this.sending = true;
    //   console.log(this.dvlaQuestions.vehiclesRegistered);

    //   let move = Object.assign({}, this.currentMove);

    //   this.dvlaQuestions["postCodePrintedV5C"] = 'No';
    //   this.dvlaQuestions["postCodeVehicleRegistered"] = this.postcodeOnV5C;

    //   move.dvlaQuestions = this.dvlaQuestions;

    //   axiosCustom
    //     .post("api/user/updateDetailsService", { move })
    //     .then(async (res) => {
    //       console.log("updateV5cDetails", res);
    //       await this.getDetails();
    //       this.redirectToHome();
    //       this.sucessDialog();
    //       this.sending = false;

    //       setTimeout(() => {}, 200);
    //     })
    //     .finally(() => {
    //       this.touched = false;
    //     });
    // },
    async sendUpdate() {
      let move = Object.assign({}, this.currentMove);

      let pendingActions = move.pendingActions;

      //license details
      if (pendingActions["dvlaLicence"]["pending"]) {
        this.validateInputs();
        move.services.selectedNINO = this.NInumber;
        move.userDetails["dateOfBirth"] = this.dob?.toISOString().split("T")[0];
      }

      //V5C Details
      if (pendingActions["dvlaVehicle"]["pending"]) {
        this.touched = true;
        this.dvlaQuestions["postCodePrintedV5C"] = "No";
        this.dvlaQuestions["postCodeVehicleRegistered"] = this.postcodeOnV5C;
      }

      const submitData = () => {
        move.dvlaQuestions = this.dvlaQuestions;
        this.sending = true;
        axiosCustom
          .post("api/user/updateDetailsService", { move })
          .then(async (res) => {
            console.log("updateDetailsService", res);
            await this.getDetails();
            this.redirectToHome();
            this.sucessDialog("Your details have been updated!");
            this.sending = false;

            setTimeout(() => {}, 200);
          })
          .finally(() => {});
      };

      //both pending
      if (pendingActions["dvlaLicence"]["pending"] && pendingActions["dvlaVehicle"]["pending"]) {
        const validateV5CDetails = await this.validateV5CDetails();
        if (validateV5CDetails) return;
        if (Object.values(this.errors).includes(true)) return;
        if (this.validateV5CPostcode()) return;
        if (this.sending) return;
        // debugger;
        submitData();
        return;
      }

      //Individual pending
      if (
        pendingActions["dvlaLicence"]["pending"] &&
        (this.sending || Object.values(this.errors).includes(true))
      )
        return;

      const validateV5CDetails = await this.validateV5CDetails();
      if (
        pendingActions["dvlaVehicle"]["pending"] &&
        (validateV5CDetails || this.validateV5CPostcode() || this.sending)
      )
        return;
      submitData();
    },
    sucessDialog(message) {
      this.$swal.fire({
        icon: "success",
        text: "Your details have been updated!",
        customClass: {
          container: "alert-cancel-move",
          confirmButton: "btn btn-primary",
        },
      });
    },
    redirectToHome() {
      this.$router.push({ name: "dashboard-home" });
    },
    async getDetails() {
      try {
        let response = await axiosCustom.get(`api/user/getDetails?email=${this.user.email}`);
        let token = localStorage.getItem("accessToken");

        let user = JSON.parse(localStorage.getItem("userData"));
        const moves = response.data.moves;
        user.moves = moves;
        localStorage.setItem("userData", JSON.stringify(user));

        // console.log(moves);
        const move = moves[moves.length - 1];

        this.$store.commit("app/setField", ["user", user]);
        this.$store.commit("app/setField", ["currentMove", move]);
        this.$store.commit("app/setField", ["lastMove", move]);
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    ...mapState("app", ["user", "urlBucket", "currentMove"]),
  },
  watch: {},
};
</script>

<style lang="scss">
.pending-actions {
  h1,
  h2,
  h3,
  p,
  th,
  tr {
    color: black;
  }

  input[type="text"] {
    border: 1px solid rgba(60, 60, 60, 0.26);
    border-radius: 5px;
    padding: 4px;
    width: 100%;
    max-width: 194px;
    margin-bottom: 5px;
  }

  .date-multi-select .vs__dropdown-toggle {
    height: 29px;
    width: 61px;
    .vs__selected {
      margin: 0px 2px 0;
      color: #000000;
    }
  }

  .error {
    font-size: 12px;
  }

  .licence-details,
  .v5c-details {
    border-radius: 30px;
    background-color: white;
    max-width: 500px;
    padding-bottom: 10px;
    padding-top: 6px;
    .mdi {
      color: #42c8a9 !important;
      cursor: pointer;
    }

    .mdi:before,
    .mdi-set {
      font-size: 4rem;
      line-height: 1;
    }

    .already-correct {
      text-decoration: underline;
      font-style: italic;
      color: #f77f14;
      cursor: pointer;
    }
  }

  .button {
    all: unset;
    box-sizing: border-box;
    display: block;
    padding: 5px;
    padding-left: 9px;
    padding-right: 9px;
    color: white;
    text-align: center;
    letter-spacing: 1.5px;
    font-weight: bolder;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
  }

  .button:active {
    box-shadow: none;
    transform: translateY(3px);
  }

  .button-update {
    background-color: #288037;
    box-shadow: 0 3px 0 #11441f;
  }
}
</style>
